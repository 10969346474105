import Axios from "axios";
import { baseUrl } from "../../main";
// import router from "../../router";

const state = () => ({
  training: [],
  trainings: [],
  trainingCategories: [],
  trainingCategory: [],
  sendEnqiry: [],
  loadingTraining: false,
  trainingRegistration: undefined,
  trainingRegistrations: [],
});

const getters = {
  allTraining: (state) => state.training,
  allTrainings: (state) => state.trainings,
  allTrainingCategory: (state) => state.trainingCategory,
  allTrainingCategories: (state) => state.trainingCategories,
  allSendEnquiry: (state) => state.sendEnqiry,
  allloadingTraining: (state) => state.loadingTraining,
  allTrainingRegistration: (state) => state.trainingRegistration,
  allTrainingRegistrations: (state) => state.trainingRegistrations,
};

const actions = {
  async createTrainingCourse({ commit }, data) {
    try {
      this.dispatch("loadingAdFunc");
      const response = await Axios.post(baseUrl + "training/create", data, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")).token,
        },
      });
      // router.push("/dashboard/trainings");
      commit("setTraining", response.data);
    } catch (error) {
      console.log(error);
    }
    this.dispatch("loadingNotAdFunc");
  },
  async createTrainingCategory({ commit }, data) {
    try {
      const response = await Axios.post(
        baseUrl + "training/categoryCreate",
        data,
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")).token,
          },
        }
      );
      commit("setTraining", response.data);
    } catch (error) {
      console.log(error);
    }
    this.dispatch("loadingNotAdFunc");
  },
  async getTrainingListing({ commit }, data) {
    try {
      this.dispatch("loadingAdFunc");
      const response = await Axios.post(baseUrl + "training", data, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")).token,
        },
      });
      commit("setTrainings", response.data);
    } catch (error) {
      console.log(error);
    }
    this.dispatch("loadingNotAdFunc");
  },
  async filterListing({ commit }, data) {
    try {
      this.dispatch("loadingAdFunc");
      const response = await Axios.post(baseUrl + "training", data, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")).token,
        },
      });
      commit("setTrainings", response.data);
    } catch (error) {
      console.log(error);
    }
    this.dispatch("loadingNotAdFunc");
  },
  async getTrainingListingFrontend({ commit }) {
    try {
      const response = await Axios.post(baseUrl + "training/front");
      commit("setTrainings", response.data);
    } catch (error) {
      console.log(error);
    }
  },
  async getSingleTraining({ commit }, id) {
    try {
      const response = await Axios.get(baseUrl + "training/single/" + id, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")).token,
        },
      });
      commit("setTraining", response.data);
    } catch (error) {
      console.log(error);
    }
  },
  async getSingleTrainingFront({ commit }, slug) {
    try {
      const response = await Axios.get(
        baseUrl + "training/front/single/" + slug
      );
      commit("setTraining", response.data);
    } catch (error) {
      console.log(error);
    }
  },
  async getSingleTrainingCategoryFront({ commit }, slug) {
    try {
      const response = await Axios.get(
        baseUrl + "training/front/singleCategory/" + slug
      );
      commit("setTraining", response.data);
    } catch (error) {
      console.log(error);
    }
  },
  async updateTrainingStatus({ commit }, id) {
    try {
      const response = await Axios.put(
        baseUrl + "training/updateStatus/",
        { id: id },
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")).token,
          },
        }
      );
      this.dispatch("getTrainingListing");
      commit("setTraining", response.data);
    } catch (error) {
      console.log(error);
    }
  },
  async sendEnquiry({ commit }, data) {
    try {
      this.dispatch("loadingAdFunc");
      let response = await Axios.post(baseUrl + "training/sendInquiry", data);
      commit("sendEnqiry", response.data);
      setTimeout(() => {
        commit("sendEnqiry", []);
      }, 4000);
    } catch (error) {
      console.log(error);
    }
    this.dispatch("loadingNotAdFunc");
  },
  async contactUs({ commit }, data) {
    try {
      this.dispatch("loadingAdFunc");
      let response = await Axios.post(baseUrl + "training/contactneco", data, {
        header: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")).token,
        },
      });
      commit("sendEnqiry", response.data);
      setTimeout(() => {
        commit("sendEnqiry", []);
      }, 4000);
    } catch (error) {
      console.log(error);
    }
    this.dispatch("loadingNotAdFunc");
  },
  async postRegistration({ commit }, data) {
    try {
      let response = await Axios.post(baseUrl + "training/registration", data);
      if (response.status == 201) {
        commit("trainingRegistration", response.data);
      }
    } catch (error) {
      console.log(error);
    }
  },
  async getRegistrations({ commit }, data) {
    try {
      let response = await Axios.post(
        baseUrl + "training/registeredList",
        data,
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")).token,
          },
        }
      );
      if (response.status == 200) {
        commit("trainingRegistrations", response.data);
      }
    } catch (error) {
      console.log(error);
    }
  },

  async geSingleTrainingCategory({ commit }, data) {
    try {
      const response = await Axios.get(baseUrl + "training/category/" + data);
      commit("setTrainingCategory", response.data);
    } catch (error) {
      console.log(error);
    }
  },

  async deleteCategory({ commit }, data) {
    try {
      const response = await Axios.delete(
        baseUrl + `training/deleteCategory/${data}`,
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")).token,
          },
        }
      );
      commit("setTrainingCategories", response.data.results);
    } catch (error) {
      console.log(error);
    }
  },

  async getTrainingCategories({ commit }) {
    try {
      const response = await Axios.get(baseUrl + "training/category");
      commit("setTrainingCategories", response.data);
    } catch (error) {
      console.log(error);
    }
  },

  async loadingAdFunc({ commit }) {
    commit("loadingAdSet", true);
  },
  async loadingNotAdFunc({ commit }) {
    commit("loadingNotAdSet", false);
  },
};

const mutations = {
  setTraining: (state, menu) => (state.training = menu),
  setTrainings: (state, menu) => (state.trainings = menu),
  setTrainingCategory: (state, menu) => (state.trainingCategory = menu),
  setTrainingCategories: (state, menu) => (state.trainingCategories = menu),
  sendEnqiry: (state, menu) => (state.sendEnqiry = menu),
  trainingRegistration: (state, menu) => (state.trainingRegistration = menu),
  trainingRegistrations: (state, menu) => (state.trainingRegistrations = menu),
  loadingAdSet: (state, loading) => (state.loadingTraining = loading),
  loadingNotAdSet: (state, loading) => (state.loadingTraining = loading),
};

export default { state, getters, actions, mutations };
